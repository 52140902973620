body {
    @apply bg-primary;
}

hr {
    @apply border-t opacity-25; /* Use graySoft from Tailwind config */
}

.card {
    @apply bg-secondary mt-6 p-3 text-white text-base; /* Use secondary for background */
}

.video-container {
    @apply w-full h-full relative; /* Full width, full height, relative positioning */
}

video {
    @apply w-full h-auto block; /* Full width, auto height for aspect ratio, block display */
}

canvas {
    @apply absolute top-0 left-0 w-full h-full pointer-events-none; /* Canvas overlay with full size */
}


/*
.time_graph{
    width: auto;
}

.time_div {
    height: 100px;
    margin-bottom: 24px;
    background-color: #333A54;
    text-align: center;
    color: white;
    padding: 10px;
}

.time_title {
    font-size: 16px;
    text-align: left;
}

.time{
    font-size: 40px;
    text-align: left;
}

.counter {
    font-size: 90px;
    line-height: 110px;
} */
/*
.tile {
    height: 170px;
    color:  white;
    padding: 10px;
    font-size: 16px;
}


.counter_holder {
    background-color: rgb(242, 190, 0);
    margin-top: 12px;

}

.speed_holder {
    margin-top: 24px;

}

.max_speed_holder {
    background-color: #3673EA;
    margin-top: 24px;
} */

/* .is_skipping {
    background-color: red;
    height: 158px;
    padding: 25px 10px 0px 10px;
    line-height: 50px;
    text-align: center;
    font-size: 25px;
    color:  white;
    margin-bottom: 12px;
}


.speed_graph_div {
    background-color: #333A54;
    margin-top: 24px;
    padding: 12px;
    font-size: 16px;
    color:  white;
} */

/* .container {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 20%;
    background-color: #303134;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo img {
    width: 50px;
    height: auto;
    margin-bottom: 20px;
} */



/* .metric .description {
    font-size: 12px;
    color: #888888;
} */

/* .metric .met_icon {
    display: block;
    width:20px;
    height:20px;
    margin:auto;
} */

/* .main-content {
    width: 60%;
    padding: 20px;
}

.video video {
    width: 100%;
    height: auto;
    background-color: black;
} */

/* .graphs {
    margin-top: 20px;
}

.graph {
    background-color: #303134;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
} */

/* .analysis {
    width: 20%;
    background-color: #303134;
    padding: 20px;
}

.analysis-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
} */

/* .analysis-header h2 {
    margin: 0;
} */

/* .new-analysis {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
} */

/* .analysis-content {
    display: flex;
    flex-direction: column;
} */

/* .analysis-item {
    background-color: #202124;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.analysis-item .status {
    font-size: 18px;
}

.analysis-item .title {
    font-size: 16px;
}

.analysis-item .description {
    font-size: 14px;
} */

/*
.mat_title{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    color:white;
}

.head_icon{
    width: 20px;
}

.title_right{
    text-align: right;
}

.fl{
    float:left;
}
.fr{
    float:right;
}


.sd{
    background-color: #2D2D2D;
} */


/* .btn-theme {
    background-color: #4caf50 !important;
    color: #ffffff;
    border-color: #4caf50 !important;
}

.btn-theme:hover {
    background-color: #3e8e41 !important;
    border-color: #3e8e41 !important;
    color: #ffffff;
}

.btn-theme:focus, .btn-theme.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 144, 254, 0.5);
}

.btn-theme:active, .btn-theme.active {
    background-color: #3e8e41 !important;
    border-color: #3e8e41 !important;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem rgba(77, 144, 254, 0.5);
} */

/* .pBadge{
    display: inline-block;
    padding: 0.35em 0.90em;
    text-align: center;
    white-space: normal;
    line-height: 1.2;
    vertical-align: baseline;
    border-radius: 50rem !important;
    font-size: 0.75em;
    font-weight: 700;
    background-color: #4caf50 !important;
    max-width: 90px;
}


.bg-red{
    background-color: red!important;
} */

/* dash_accordion */
/* .dash_accordion .accordion-item{
    background-color: #212121;
    border: transparent !important;
    margin-bottom: 10px;
} */

/* .dash_accordion .accordion-button,
.dash_accordion .accordion-button:not(.collapsed){
    background-color: #2D2D2D;
    color: white;
    box-shadow: unset;
} */

/* .dash_accordion .accordion-body{
    background-color: #2d2d2d7d;
    padding: 0;
} */

/* .dash_accordion .accordion-button {
    padding: 5px;
}

.dash_accordion .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon);
}

.acc_tab_body{
    padding: 10px;
} */

.acc_tab.nav-tabs .nav-link {
    @apply bg-white text-primaryAccent border-0 px-4 py-3; /* Use Tailwind classes for white background and primary accent color */
}

.acc_tab.nav-tabs .nav-link.active {
    @apply bg-primaryAccent text-black; /* Use primaryAccent for active background and black for text */
}

.acc_tab.nav-tabs .nav-item:first-child .nav-link {
    @apply rounded-tl-[25px] rounded-bl-[25px] rounded-tr-none rounded-br-none; /* Custom rounded corners for the first tab */
}

.acc_tab.nav-tabs .nav-item:last-child .nav-link {
    @apply rounded-tr-[25px] rounded-br-[25px] rounded-tl-none rounded-bl-none; /* Custom rounded corners for the last tab */
}

.tab_heading {
    @apply text-primaryAccent; /* Use primaryAccent for heading color */
}

.tab_head_img {
    @apply w-full h-[100px] object-cover; /* Maintain full width, fixed height, and object-fit behavior */
}

.menu_item {
    @apply h-full bg-primary pt-2.5 px-2.5; /* Full height, primary background, and padding */
}

.menu_left {
    @apply border-r border-white/25; /* Right border with 25% opacity of white */
}

.menu_right {
    @apply border-l border-white/25; /* Left border with 25% opacity of white */
}



/* progress */
.progress{
    width: 30px;
    height: 30px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
}
.progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}
.progress .progress-left{
    left: 0;
}
.progress .progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress .progress-left .progress-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}
.progress .progress-right{
    right: 0;
}
.progress .progress-right .progress-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.progress .progress-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    font-size: 8px;
    color: white;
    line-height: 30px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
}

.progress.red .progress-bar{
    border-color: red;
}

.progress .progress-left .progress-bar{
    animation: loading-2 1.5s linear forwards 1.8s;
}


/* 10% */
.progress .progress-right .progress-bar.ten{
    animation: loading-3 1.5s linear forwards 1.8s;
}
.progress .progress-left .progress-bar.ten{
    animation: loading-4 1.5s linear forwards 1.8s;
}



@keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@keyframes loading-2{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}

@keyframes loading-3{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(50deg);
        transform: rotate(50deg);
    }
}
@keyframes loading-4{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes loading-5{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}

@media only screen and (max-width: 990px){
    .progress{
        margin-bottom: 20px;
    }
}


/* accordion */
.accordion {
    @apply text-graySoft; /* Use graySoft for text color */
}

.accordion-item {
    @apply flex items-center w-full px-4 py-4 text-left text-base border-0 transition-all duration-150 ease-in-out bg-secondary text-white justify-between;
    /* Tailwind classes for the styles */
    border-top-left-radius: calc(0.375rem - 1px); /* Custom radius for top-left */
    border-top-right-radius: calc(0.375rem - 1px); /* Custom radius for top-right */
}

.accordion-body {
    @apply bg-secondary/80 p-0; /* Use secondary with 80% opacity for background */
}

.accordion-body .nav-link {
    @apply block px-2 py-1.5 text-transparent border-0 transition-all duration-150 ease-in-out cursor-pointer;
    /* Tailwind classes for link styles */
}





/* Custom style */
.header-right {
	width: calc(100% - 3.5rem);
}

@media only screen and (min-width: 768px) {
	.header-right {
		width: calc(100% - 16rem);
	}
}

.custom-transition {
	transition: width 0.8s ease-in-out;
}

/* In your CSS or Tailwind for middle content */
.ml-12 { /* margin-left for when sidebar is collapsed */
  	margin-left: 3rem;
}
.ml-48 { /* margin-left for when sidebar is open */
  	margin-left: 12rem;
}
